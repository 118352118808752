import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, flatMap, switchMap } from 'rxjs/operators';

import { AppConfig } from '../app.config';

@Injectable({
    providedIn: 'root'
})
export class HomeService {
    hraderOptions: any;
    constructor(
        private http: HttpClient
    ) {
        this.hraderOptions = {
            headers: new HttpHeaders({
                Authorization: 'Token 22826e9f2272f685380bcd81adc8f3eef1582d3a',
                'Content-Type': 'application/json'})
        };
    }
    /**
     * Get the home page slider
     */
    homePageSlider() {
        return this.http.get(`https://api.cakehubs.com/api/v1/banners/?slug=web-home-page`, this.hraderOptions)
            .pipe(switchMap((resp: any) => {
                return of(resp);
            }));
    }
    /**
     * Get home page details
     */
    homePageProducts() {
        return this.http.get(`https://api.cakehubs.com/api/v1/cards?type=web`, this.hraderOptions)
            .pipe(switchMap((resp: any) => {
                return of(resp);
            }));
    }
    /**
     * Submit the search field
     */
    getProduct(searchPrams: {} = {}) {
        const arrayStr = [];
        const pramsStr = Object.entries(searchPrams).map(([key, val]) => { if (val !== null && val !== '') {
            arrayStr.push(`${key}=${val}`);
        } } );
        const queryString = arrayStr.join('&');
        return this.http.get(`${AppConfig.settings.apiRoot}${AppConfig.settings.api.getProducts}?${queryString}`, this.hraderOptions)
            .pipe(switchMap((resp: any) => {
                return of(resp);
            }));
    }

    getPageContent(pageName: string) {
        return this.http.get(`${AppConfig.settings.apiRoot}${AppConfig.settings.api.pageContent}?slug=${pageName}`, this.hraderOptions)
            .pipe(switchMap((resp: any) => {
                return of(resp);
            }));
    }
}
