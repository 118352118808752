import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    submitted = false;
    isDisabled = false;
    @Output() isRegistered = new EventEmitter();
    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private toastr: ToastrService,
    ) { }

    ngOnInit() {
        const emailPattern = '[a-zA-Z0-9]+(?!.*--)(?!.*__)[a-zA-Z0-9._-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}';
        this.registerForm = this.formBuilder.group({
            name: [null, [Validators.required]],
            email: ['', [Validators.required, Validators.email, Validators.pattern(emailPattern)]],
            mobile: ['', [Validators.required, Validators.pattern('[1-9][0-9]+'), Validators.minLength(10), Validators.maxLength(10)]],
            password: ['', [Validators.required]]
        });
    }
    /**
     * Get register form controls
     */
    get f() { return this.registerForm.controls; }
    /**
     * On submit
     */
    onSubmit() {
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        this.authService.registerUser(this.registerForm.value)
            .pipe()
            .subscribe(
                data => {
                    if (data.status) {
                        this.toastr.success('Success!', 'User Registered successfully');
                        this.isDisabled = true;
                        this.isRegistered.emit(true);
                    } else {
                    }
                },
                error => {
                   console.log(error);
                });
    }
    /**
     * Restrice mobile number field
     */
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
        return true;
    }

}
