import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'cakeHubs';
  loadFooter = false;
  isCheckoutPage = false;
  isShowCatgoryNav = true;
  isFooter = true;
  constructor(private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/checkout') {
          this.isCheckoutPage = true;
          this.isFooter = false;
        } else {
          this.isCheckoutPage = false;
        }
     }
    });
  }
  ngOnInit() {
    setTimeout(() => {
      this.loadFooter = true;
    }, 800);
  }
}
