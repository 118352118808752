import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, flatMap, switchMap } from 'rxjs/operators';
import { Broadcaster } from '../_helpers/broadcaster';

import { User } from '../_models/user';
import { AppConfig } from '../app.config';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;
    headers: any;
    hraderOptions: any;
    constructor(
        private http: HttpClient,
        private broadcaster: Broadcaster
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('chUser')));
        this.user = this.userSubject.asObservable();
        this.hraderOptions = {
            headers: new HttpHeaders({
                Authorization: 'Token 22826e9f2272f685380bcd81adc8f3eef1582d3a',
                'Content-Type': 'application/json'})
        };
    }

    /**
     * Register function
     */
    registerUser(args: any) {
        return this.http.post(`${AppConfig.settings.apiRoot}${AppConfig.settings.api.userRegister}`, args, this.hraderOptions)
            .pipe(switchMap((resp: any) => {
                if (resp.status) {
                    console.log('resp', resp);
                    localStorage.setItem('chUser', JSON.stringify(resp.data));
                    this.broadcaster.broadcast('chUser', resp.data);
                    this.userSubject.next(resp.data);
                }
                return of(resp);
            }));
    }
    /**
     * Login authenticate
     * @param email Email address
     * @param password Password
     */
    login(email, pass): Observable<any> {
        return this.http.post(`${AppConfig.settings.apiRoot}${AppConfig.settings.api.userLogin}`, 
        { mobile: email, password: pass }, this.hraderOptions)
            .pipe(switchMap((resp: any) => {
                if (resp.status) {
                    localStorage.setItem('chUser', JSON.stringify(resp.data));
                    this.broadcaster.broadcast('chUser', resp.data);
                    this.userSubject.next(resp.data);
                }
                return of(resp);
            }));
    }
    /**
     * Get current logged user value
     */
    public get userValue(): User {
        return this.userSubject.value;
    }
    /**
     * Logout from session
     */
    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('chUser');
        this.userSubject.next(null);
        window.location.replace('/');
    }
    /**
     * Check if user logged in
     */
    isUserLoggedIn() {
        return this.userValue ? true : false;
    }
    /**
     * Check user mobile exist or not
     */
    isMobileExist(mobile): Observable<any> {
        return this.http.get(`${AppConfig.settings.apiRoot}${AppConfig.settings.api.checkoutAuth}?mobile=${mobile}`, this.hraderOptions)
            .pipe(switchMap((resp: any) => {
                return of(resp);
            }));
    }
}
