import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SnackbarModule } from 'ngx-snackbar';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';

import { AppConfig } from './app.config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderNavComponent } from './_components/header-nav/header-nav.component';
import { AccountComponent } from './layout/account/account.component';
import { LoginComponent } from './layout/account/login/login.component';
import { RegisterComponent } from './layout/account/register/register.component';
import { Broadcaster } from './_helpers/broadcaster';
import { MiniCartComponent } from './_components/mini-cart/mini-cart.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { TermsComponent } from './pages/terms/terms.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';

export function initConfig(config: AppConfig) {
    return () => config.load();
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        HeaderNavComponent,
        AccountComponent,
        LoginComponent,
        RegisterComponent,
        MiniCartComponent,
        PrivacyComponent,
        TermsComponent,
        AboutComponent,
        ContactComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot(), // ToastrModule added
        NgbModule,
        SnackbarModule,

    ],
    providers: [
        Broadcaster,
        AppConfig,
        //{provide : LocationStrategy, useClass: HashLocationStrategy}
        { provide: APP_INITIALIZER, useFactory: initConfig, deps: [AppConfig], multi: true },
    ],
    exports: [
        AccountComponent,
        LoginComponent,
        RegisterComponent,
        AboutComponent,
        ContactComponent,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
