import { Component, OnInit } from '@angular/core';
import { HomeService } from './../../_services/home.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  privacy: string;

  constructor(private homeService: HomeService) { }

  ngOnInit() {
    this.getPrivacyPolicy();
  }

  getPrivacyPolicy() {
    this.homeService.getPageContent("privacy-policy").subscribe((result: any) => {
      if (result.status) {
        this.privacy = result.results[0].content;
      }
    });
  }

}
