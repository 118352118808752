import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject, BehaviorSubject,  of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class CartService {
    private subject = new ReplaySubject<any>();
    httpParams: any;
    user: any;
    existingCartItems: any = [];
    private cartSubject: BehaviorSubject<any>;
    constructor(
        private http: HttpClient,
        private authService: AuthService,
    ) {
        this.cartSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('cartItems')));
        this.httpParams = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
    }
    /**
     * Get current cart items
     */
    public get getCartItems(): any {
        return this.cartSubject.asObservable();
    }
    /**
     * Cart service
     */
    setItemInCart(item) {
        this.existingCartItems = (JSON.parse(localStorage.getItem('cartItems'))) ? JSON.parse(localStorage.getItem('cartItems')) : [];
        if (!this.existingCartItems) {
            item.quantity = 1;
            this.existingCartItems.push(item);
        } else {
            const index = this.existingCartItems.findIndex((value) => value.id === item.id );
            if (index !== -1) {
                this.existingCartItems[index].quantity += 1;
            } else {
                item.quantity = 1;
                this.existingCartItems.push(item);
            }
        }
        localStorage.setItem('cartItems', JSON.stringify(this.existingCartItems));
        this.cartSubject.next(this.existingCartItems);
    }
    /**
     * Delete cart item
     */
    deleteCartItems(itemId) {
        this.existingCartItems = (JSON.parse(localStorage.getItem('cartItems'))) ? JSON.parse(localStorage.getItem('cartItems')) : [];
        const index = this.existingCartItems.findIndex((value) => value.id === itemId);
        if (index !== -1) {
            this.existingCartItems.splice(index, 1);
            console.log('Cart Service', this.existingCartItems);
            localStorage.setItem('cartItems', JSON.stringify(this.existingCartItems));
            this.cartSubject.next(this.existingCartItems);
        }
    }
    /**
     * Make cart item empty
     */
    emptyCart() {
        localStorage.setItem('cartItems', JSON.stringify([]));
        this.cartSubject.next([]);
    }
}
