import { Component, OnInit, Renderer2 } from '@angular/core';

import { CartService } from './../../_services/cart.service';
import { AppConfig } from './../../app.config';
import { SnackbarService } from 'ngx-snackbar';


@Component({
    selector: 'app-mini-cart',
    templateUrl: './mini-cart.component.html',
    styleUrls: ['./mini-cart.component.css']
})
export class MiniCartComponent implements OnInit {
    cartData: any = [];
    itemCount: any;
    miniCartItems: any = [];
    config =  AppConfig;
    subTotal: any = 0;
    totalDeliveryCharge: any = 0;
    totalSaving: any = 0;
    totalSavingPercent: any = 0;
    total: any = 0;
    constructor(
        private cartService: CartService,
        private snackbarService: SnackbarService,
        private renderer: Renderer2,
    ) { }

    ngOnInit() {
        this.cartData = this.cartService.getCartItems;
        // Get existing cart items
        this.cartData.subscribe((res: any) => {
            if (res) {
                this.miniCartItems = [];
                this.itemCount = res.length;
                this.miniCartItems = res;
                // Calculate checkout data
                if ( this.miniCartItems) {
                    this.subTotal = this.totalDeliveryCharge = this.subTotal = this.totalSaving = this.totalSavingPercent =  0;
                    this.miniCartItems.map((value, key) => {
                        this.subTotal               = this.subTotal + value.selling_price;
                        this.totalDeliveryCharge    = this.totalDeliveryCharge + value.shipping_fee;
                        this.total                  = this.subTotal + this.totalDeliveryCharge;
                        this.totalSaving            = this.totalSaving + (value.mrp_price - value.selling_price);
                        this.totalSavingPercent     = this.totalSavingPercent + ( 100 - (value.selling_price / value.mrp_price * 100));
                    });
                }
            }
        });
    }
    /**
     * Delete cart items
     */
    deleteCartItems(cartId) {
        this.cartService.deleteCartItems(cartId);
        this.addNotifications('This item has been deleted from cart successfully.');
    }
    /**
     * Add notification to the user screen
     */
    addNotifications(message: string) {
        this.snackbarService.add({
            msg: message,
            timeout: 5000,
            action: {
                text: null // no action
            },
            onAdd: () => {
                this.clearSnackbar();
            },
        });
    }
    /**
     * Clear snackbar
     */
    clearSnackbar() {
        this.snackbarService.clear();
    }
    /**
     * Add class to body tag
     */
    addClassBody() {
        this.renderer.addClass(document.body, 'toggled');
    }
    /**
     * Remove toggled class from body
     */
    closeCartModal() {
        this.renderer.removeClass(document.body, 'toggled');
    }

}
