import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'ngx-snackbar';
import { HomeService } from './../../_services/home.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    searchForm: FormGroup;
    submitted = false;
    loading: any = false;
    constructor(
        private fb: FormBuilder,
        private snackbarService: SnackbarService,
        private homeService: HomeService,
        private router: Router,

    ) { }

    ngOnInit() {
        this.searchForm = this.fb.group({
            searchField: ['', [Validators.required]],
        });
    }
    /**
     * Convenience getter for easy access to form fields
     */
    get f() { return this.searchForm.controls; }

    /**
     * Submit form
     */
    onSubmit() {
        if (this.searchForm.invalid) {
            this.addNotifications('Please enter the search text.');
            return;
        }
        const searchParams = {
            search: this.searchForm.get('searchField').value,
            // page: 1,
            // limit: 12,
        };
        const arrayStr = [];
        const pramsStr = Object.entries(searchParams).map(([key, val]) => { if (val !== null && val !== '') {
            arrayStr.push(`${key}=${val}`);
        } } );
        const queryString = arrayStr.join('&');
        this.router.navigate(['/products'], { queryParams: searchParams });
    }
    /**
     * Add notification to the user screen
     */
    addNotifications(message: string) {
        this.snackbarService.add({
            msg: message,
            timeout: 5000,
            action: {
                text: null // no action
            },
            onAdd: () => {
                this.clearSnackbar();
            },
        });
    }
    /**
     * Clear snackbar
     */
    clearSnackbar() {
        this.snackbarService.clear();
    }
}
