import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { IAppConfig } from './_models/app-config.model';

@Injectable()
export class AppConfig {
    static settings: IAppConfig;
    /**
     * Load constructor
     */
    constructor(private http: HttpClient) {}
    /**
     * Use to get the data found in the second file (config file)
     */
    public getConfig(key: any) {
        return AppConfig.settings[key];
    }

    load() {
        const jsonFile = `/assets/config/config.${environment.name}.json` + '?v=' + new Date().getTime();
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response: IAppConfig) => {
               AppConfig.settings = (response as IAppConfig);
               resolve();
            }).catch((response: any) => {
               reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}
