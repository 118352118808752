import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../../../_services/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    submitted = false;
    isDisabled = false;
    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private toastr: ToastrService
    ) {

    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });
    }
    /**
     * Get Form Controls
     */
    get f() { return this.loginForm.controls; }
    /**
     * OnSubmit login form
     */
    onSubmit() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.authService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    if (data.status) {
                        this.toastr.success('User logedin successfully', 'Success');
                        this.isDisabled = true;
                    } else {
                    }
                },
                error => {
                    if (!error.error.status) {
                        this.toastr.error(error.error.message, 'Error');
                    }
                });
    }

}
