import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/_services/home.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  about: string;

  constructor(private homeService: HomeService) { }

  ngOnInit() {
    this.getAboutUs();
  }

  getAboutUs() {
    this.homeService.getPageContent("about-us").subscribe((result: any) => {
      if (result.status) {
        this.about = result.results[0].content;
      }
    });
  }

}
