import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { TermsComponent } from './pages/terms/terms.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';

const routes: Routes = [
    { path: '', loadChildren: './home/home.module#HomeModule' },
    { path: 'products', loadChildren: './products/products.module#ProductsModule'},
    { path: 'products/:id', loadChildren: './products/product-details/product-details.module#ProductDetailsModule'},
    { path: 'checkout', loadChildren: './check-out/check-out.module#CheckOutModule' },
    { path: 'privacy-policy', component: PrivacyComponent},
    { path: 'terms-of-use', component: TermsComponent},
    { path: 'about-us', component: AboutComponent},
    { path: 'contact-us', component: ContactComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
