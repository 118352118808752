import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { Broadcaster } from '../../_helpers/broadcaster';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
    isLogin = true;
    isRegister = false;
    user: any;
    constructor(
        private authService: AuthService,
        private broadcaster: Broadcaster
    ) {
    }

    ngOnInit() {
        this.user = this.authService.userValue;
        this.broadcaster.on('chUser').subscribe(res => {
            this.user = res;
        });
    }
    /**
     * Show Login/Register Form
     */
    loginRegisterForm(formType) {
        if (formType === 'login') {
            this.isLogin    = true;
            this.isRegister = false;
        } else if (formType === 'register') {
            this.isRegister = true;
            this.isLogin    = false;
        }
    }
    /**
     * Logout
     */
    logout() {
        this.authService.logout();
    }
    /**
     * Check user logedin or not
     */
    checkIsLogedIn(value) {
        if (value) {
            this.isLogin = value;
            this.isRegister = false;
        }
    }

}
