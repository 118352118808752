import { Component, OnInit } from '@angular/core';
import { HomeService } from './../../_services/home.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  terms: string;

  constructor(private homeService: HomeService) { }

  ngOnInit() {
    this.getTermsOfUse();
  }

  getTermsOfUse() {
    this.homeService.getPageContent("terms-of-use").subscribe((result: any) => {
      if (result.status) {
        this.terms = result.results[0].content;
      }
    });
  }

}
